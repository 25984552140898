import { Home } from '@mui/icons-material';
import { alpha, Box, Card, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMapDialog } from '../../../../../../../../helpers/hooks';
import { editAddress } from '../../../../../../../../redux/reducers/localAddresses/reducer';
import { RootState } from '../../../../../../../../redux/types';
import DoneIcon from '../../../../../../../../shared/components/DoneIcon';
import {
  Address,
  DeliveryFeeType,
} from '../../../../../../../../shared/models';
import AddressCardActions from './components/AddressCardActions';

interface StyleProps {
  color: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 118,
    padding: 12,
    borderRadius: 4,
    borderColor: ({ color }) => color,

    transition: 'all 0.2s',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ({ color }) => alpha(color, 0.05),
    },
  },
}));

interface AddressCardProps {
  address: Address;
  formik: FormikProps<any>;
  handleEditAddress: (address: Address) => void;
}

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  formik,
  handleEditAddress,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [MapDialog, mapControls] = useMapDialog();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const { values, setFieldValue } = formik;

  const isSelected = values.address?.guid === address.guid;

  const classes = useStyles(
    !isSelected ? { color: '#969C98' } : { color: theme.palette.primary.main }
  );

  const handleSelectAddress = async (): Promise<void> => {
    if (isSelected) {
      return;
    }

    const hasMap =
      establishment?.deliverySettings?.deliveryFeeType !==
        DeliveryFeeType.fixedFee &&
      establishment?.deliverySettings?.deliveryFeeType !==
        DeliveryFeeType.neighborhoodFee;

    const stateAddress = { ...address };

    if (!address.coordinates && hasMap) {
      const coordinates = await mapControls.open({
        street: stateAddress.street,
        city: stateAddress.city.name,
        uf: stateAddress.city.state.uf,
        hideSearchBar: true,
        closeInX: true,
        number: stateAddress.number,
        neighborhood: stateAddress.neighborhood,
      });
      stateAddress.coordinates = coordinates[0];
      dispatch(editAddress(stateAddress));
    }
    setFieldValue('address', stateAddress);
  };

  return (
    <>
      <MapDialog />

      <Card
        data-testid="address-card"
        variant="outlined"
        className={classes.root}
        onClick={handleSelectAddress}
      >
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          <Home style={{ color: '#313533' }} />

          <Box>
            <Typography style={{ fontSize: '.875rem' }}>
              {`Rua ${address.street.replace(/^rua/i, '')}, ${address.number}`}
            </Typography>
            <Typography style={{ fontSize: '.875rem', fontWeight: 400 }}>
              {`${address.neighborhood}, ${address.city.name} - ${address.city.state.uf}`}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: 5 }}
        >
          {isSelected ? <DoneIcon small /> : <Box width={24} height={24} />}

          <AddressCardActions
            addressGuid={address.guid ?? ''}
            handleEditAddress={() => handleEditAddress(address)}
          />

          <Box width={24} height={24} />
        </Box>
      </Card>
    </>
  );
};

export default AddressCard;
