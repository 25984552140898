import dynamic from 'next/dynamic';
import React, { useRef, useState } from 'react';
import BackdropProgressIndicator from '../../shared/components/BackdropProgressIndicator';
import { Coordinates } from '../../shared/models';

const MapDialog = dynamic(() => import('../../shared/components/MapDialog'), {
  loading: () => <BackdropProgressIndicator />,
});

type useRefType = Array<(value: any) => void>;

type openType = {
  initialCenter?: Coordinates;
  hideSearchBar?: boolean;
  headerChildren?: React.ReactNode;
  street?: string;
  city?: string;
  uf?: string;
  number?: string;
  neighborhood?: string;
  closeInX?: boolean;
};
type Controllers = {
  open: (value: openType) => Promise<Coordinates[]>;
};

type useMapDialogReturn = [React.FC, Controllers];

const useMapDialog = (): useMapDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogData, setDialogData] = useState<openType>({} as openType);

  const promisesResolve = useRef<useRefType>([]);
  const promisesReject = useRef<useRefType>([]);

  const open = (data: openType): Promise<Coordinates[]> => {
    setIsOpen(true);
    setDialogData(data);
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      promisesResolve.current.push(resolve);
      promisesReject.current.push(reject);
    });
  };

  const ResolveValue = (value: Coordinates[]): void => {
    promisesResolve.current.map((resolve) => resolve(value));
    setIsOpen(false);
  };

  const RejectValue = (): void => {
    promisesReject.current.map((reject) => reject([]));
    setIsOpen(false);
  };

  const DialogComponent: React.FC = () => {
    return (
      <MapDialog
        open={isOpen}
        onClose={RejectValue}
        onSubmit={ResolveValue}
        initialCenter={dialogData.initialCenter}
        hideSearchBar={dialogData.hideSearchBar}
        headerChildren={dialogData.headerChildren}
        street={dialogData.street}
        city={dialogData.city}
        uf={dialogData.uf}
        number={dialogData.number}
        closeInX={dialogData.closeInX}
        neighborhood={dialogData.neighborhood}
      />
    );
  };

  return [DialogComponent, { open }];
};

export { useMapDialog };
